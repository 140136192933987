<template>
<div>
	<div v-if="mode == 'add'" @click.prevent="$bvModal.show('collection-manager-' + item._id);" class="add-collection-card d-flex align-items-center justify-content-center border rounded">
		<div class="add d-flex align-items-center justify-content-center">
			<div class="material-icons-outlined md-light">add</div>
		</div>
	</div>
	<b-modal hide-header centered hide-footer :id="'collection-manager-' + item._id" @hidden="cleanupModal">
		<div v-if="!addCollection && mode != 'add'">
			<header style="margin-bottom: 2rem;">
				<div class="h2 mb-2">
					Add to Collection
				</div>
				<div class="text-muted">
					Add this stylesheet to one or more collections
				</div>
			</header>
			<div>
				<ul class="collection-buckets list-unstyled m-0">
					<li class="bucket border rounded p-3" :class="{ bucketed: collection.styles.includes(item._id) }" v-for="collection in collections" :key="collection._id" @click.prevent="toggleStyle(collection)">
						<div class="d-flex">
							<!-- <div class="badge d-flex align-items-center justify-content-center rounded">
										<span>
											{{ collection.name[0].toUpperCase()  }}
										</span>
									</div> -->
							<div class="d-flex align-items-center justify-content-between flex-grow-1 text-left">
								<div class="h4">
									{{ collection.name }}
								</div>
								<div class="meta small text-muted ml-3">
									{{ collection.styles.length }} styles
								</div>
							</div>
						</div>
					</li>
				</ul>
				<div class="d-flex w-100 mt-2">
					<div>
						<b-button variant="outline-dark" size="sm" @click.prevent="$bvModal.hide('collection-manager-' + item._id)">
							Done
						</b-button>
					</div>
					<div>
						<b-button class="ml-2" variant="outline-dark" size="sm" @click.prevent="addCollection = true;">
							Create New Collection
						</b-button>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<header style="margin-bottom: 2rem;">
				<div class="h2">
					Create New Collection
				</div>
			</header>
			<div>
				<ValidationObserver v-slot="{ handleSubmit, reset, pristine, failed }">
					<b-form @submit.prevent="handleSubmit(save)" @reset.prevent="reset">
						<b-form-group class="text-left">
							<ValidationProvider v-slot="v" name="Collection Name" rules="required|min:1">
								<b-form-input
									placeholder="Collection Title"
									maxlength="40"
									v-model="collection.name"
									:state="getValidationState(v)"></b-form-input>
								<b-form-invalid-feedback id="input-2-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
							</ValidationProvider>
						</b-form-group>
						<div style="margin-top: 2rem;" class="d-flex align-items-center">
							<b-button variant="primary" type="submit" size="sm" @click.prevent="create" :disabled="pristine || failed">
								Save
							</b-button>
							<b-button class="ml-2" size="sm" variant="outline-dark" @click.prevent="mode === 'add' ? $bvModal.hide('collection-manager-' + item._id) : (addCollection = false)">
								Cancel
							</b-button>
						</div>
					</b-form>
				</ValidationObserver>
			</div>
		</div>
	</b-modal>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from '@/modules/vendor/validate';
export default {
    name: 'CollectionManagerModal',
    props: ['item', 'mode'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            collections: [],
            addToCollections: [],
            addCollection: false,
            collectionName: '',
            collection: false
        };
    },
    computed: {
        Collections() {
            return this.$FeathersVuex.api.Collections;
        }
    },
    methods: {
        async create() {
            await this.collection.save();
            if (this.mode == "add") {
                this.$bvModal.hide("collection-manager-" + this.item._id);
            } else {
                this.collections.unshift(this.collection);
                this.addCollection = false;
            }
            this.collection = new this.Collections;
        },
        async loadCollections() {
            this.collections = await this.Collections.find().then(res => res.data);
        },
        cleanupModal() {
            if (this.mode != "add") {
                this.createCollection = false;
            }
        },
        toggleStyle(collection) {
            if (collection.styles.includes(this.item._id)) {
                collection.styles.splice(collection.styles.indexOf(this.item._id));
            } else {
                collection.styles.push(this.item._id);
            }
            collection.save();
        }
    },
    async mounted() {
        console.log('COLLECTION:', this.item);
        await this.loadCollections();
        this.collection = new this.Collections;
    }
}
</script>

<style lang="scss" scoped>
.collection-buckets {
    max-height: 340px;
    overflow-y: scroll;
}

.bucket {
    border-color: var(--color-500) !important;
    cursor: pointer;
    color: white;
    margin-bottom: 1rem;
	border-width: 2px !important;
    &.bucketed {
        background-color: var(--color-primary);

        border-color: var(--color-primary) !important;
        &:hover {
            background-color: var(--color-primary);
        }
        .meta {
            color: var(--color-white) !important;
        }
    }

    &:hover {
        background-color: var(--color-800);
    }

}
.add-collection-card {
	cursor: pointer;
	min-height: 200px;
	border-color: var(--color-500) !important;
	.add {
		width: 3rem;
		height: 3rem;
		border-radius: 4rem;
		border: 1px solid var(--color-500);
	}
}
</style>
