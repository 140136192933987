<template>
	<div>
		<header class="header">
			<div class="display-2 mb-0">
				Collections
			</div>
		</header>
		<div class="collection-list">
			<div v-for="collection in collections" :key="collection._id" @click.prevent="$router.push('/collections/' + collection._id)" class="d-flex flex-column folder">
				<div class="flex-grow-1">
					<div class="title h2 mb-2">
						{{ collection.name }}
					</div>
				</div>
				<div class="d-flex justify-content-between align-items-end">
					<div class="content d-flex align-items-center">
						<div class="icon mr-3">
							<span class="material-icons-outlined md-light">layers</span>
							<!--
								Monogram Value
								{{ collection.name[0].toUpperCase() }}
							-->
						</div>
						<div class="meta">
							<div class="mb-1">{{ collection.styles.length }} stylesheets</div>
							<div class="text-muted" style="font-size: 0.7rem;">
								Updated {{ collection.updatedAt | moment("from") }}
							</div>
						</div>
					</div>
					<div>
						<div class="arrow-button ml-3 d-flex align-items-center justify-content-center" @click.prevent="addCollection = true;">
							<span class="material-icons-outlined md-light">east</span>
						</div>
					</div>
				</div>
			</div>
			<CollectionManagerModal mode="add" :item="{_id: 'new'}" />
		</div>
	</div>
</template>

<script>
import CollectionManagerModal from '@/components/CollectionManagerModal';
export default {
	name: 'CollectionsList',
	components: {
		CollectionManagerModal
	},
	data () {
		return {
			collections: []
		};
	},
	computed: {
		Collections () {
			return this.$FeathersVuex.api.Collections;
		}
	},
	methods: {
		loadNewCollection(collection) {
			this.collections.unshift(collection);
		},
		async loadCollections () {
			this.collections = await this.Collections.find().then(res => res.data);
		}
	},
	async mounted () {
		this.Collections.on('created', this.loadNewCollection);
		this.Collections.on('removed', this.loadCollections);
		this.loadCollections();
	},
	onDestroyed () {
		this.Collections.off('created', this.loadNewCollection);
		this.Collections.off('removed', this.loadCollections);
	}
}
</script>

<style lang="scss" scoped>

	.header {
		padding: 0 1.5rem 2rem;
		.display-2 {
			font-size: 2.5rem;
			line-height: 1;
		}
	}

	.collection-list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 2rem;
		row-gap: 2rem;
		padding: 0 1.5rem;

		@media (min-width: 1000px) {
			grid-template-columns: 1fr 1fr;
		}

		@media (min-width: 1400px) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@media (min-width: 1800px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		@media (min-width: 2000px) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}

		.folder {
			padding: 1.5rem;
			min-height: 190px;
			cursor: pointer;
			background-color: var(--color-700);
			transition: var(--property-transition);
			border-radius: var(--property-border-radius);
			.title {
				font-size: 1.25rem;
			}
			.icon {
				color: var(--color-100);
				transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
			}
			.meta {
				font-size: var(--property-font-size-xs);
			}
			&:hover {
				box-shadow: 0 0 50px rgba(0,0,0,0.25);
				transform: scale(1.015);
				.icon {
					color: var(--color-white);
				}
			}
			.content {
				z-index: 999;
				position: relative;
				width: 100%;
				transform: translateY(calc(100% - 2rem));
				transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
				.meta {
					opacity: 0;
					visibility: hidden;
					transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
				}
			}
			&:hover {
				.content {
					transform: translateY(calc(100% - 2.5rem));
					.meta {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
		.add-collection-card {
			cursor: pointer;
			min-height: 200px;
			border-color: var(--color-500) !important;
			.add {
				width: 3rem;
				height: 3rem;
				border-radius: 4rem;
				border: 1px solid var(--color-500);
			}
		}
	}
</style>
